import React, {Component} 			from 'react';
import Popup            			from 'reactjs-popup';
import  {Slide}  					from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import sampleTest001 from './assets/FigTest_001.png'
import sampleTest002 from './assets/FigTest_002.png'
import splash_sample1 from './assets/splash/splash_sample1.jpg'
import icon_rightArrow from './assets/icons/arrowRight.png'
import icon_leftArrow from './assets/icons/arrowLeft.png'

// Função de carregamento dinâmico de imagens _______________________________________________________________________
function checkEmpty(a) {
	if(a.length){
		return true;
	}
	else{
		return false;
	}
}
function importAll(r) {
	let out = [];
  	r.keys().map((item, index) => 
  		{ 
  			let images = {};
  			images['img'] = r(item); 
  			out.push(images)
		}
	);
  	return out;
}


// Carregar imagens _________________________________________________________________________________________________
const img_resguardos_duche 		= importAll(require.context('./imglib/resguardos_duche/', false, /\.(png|jpe?g|JPE?G|svg)$/));
const img_acrilico 				= importAll(require.context('./imglib/acrilico/', false, /\.(png|jpe?g|JPE?G|svg)$/));
const img_claraboias 			= importAll(require.context('./imglib/claraboias/', false, /\.(png|jpe?g|JPE?G|svg)$/));
const img_cortinas 				= importAll(require.context('./imglib/cortinas/', false, /\.(png|jpe?g|JPE?G|svg)$/));
const img_envidracados 			= importAll(require.context('./imglib/envidracados/', false, /\.(png|jpe?g|JPE?G|svg)$/));
const img_espelhos 				= importAll(require.context('./imglib/espelhos/', false, /\.(png|jpe?g|JPE?G|svg)$/));
const img_ferragens 			= importAll(require.context('./imglib/ferragens/', false, /\.(png|jpe?g|JPE?G|svg)$/));
const img_guarda_corpos 		= importAll(require.context('./imglib/guarda_corpos/', false, /\.(png|jpe?g|JPE?G|svg)$/));
const img_outros 				= importAll(require.context('./imglib/outros/', false, /\.(png|jpe?g|JPE?G|svg)$/));
const img_palas 				= importAll(require.context('./imglib/pala/', false, /\.(png|jpe?g|JPE?G|svg)$/));
const img_portas 				= importAll(require.context('./imglib/portas/', false, /\.(png|jpe?g|JPE?G|svg)$/));
const img_resguardos_banheira 	= importAll(require.context('./imglib/resguardos_banheira/', false, /\.(png|jpe?g|JPE?G|svg)$/));
const img_revestimentos 		= importAll(require.context('./imglib/revestimentos/', false, /\.(png|jpe?g|JPE?G|svg)$/));
const img_vidro 				= importAll(require.context('./imglib/vidro/', false, /\.(png|jpe?g|JPE?G|svg|jfif)$/));

var cor_mv_leve  = '#9adee9'
var cor_mv_base  = '#00acc8'
var cor_mv_media = '#66cdde'

var resguardos_duche =
{
	title:'Resguardos Duche',
	have_img: checkEmpty(img_resguardos_duche),
	cor: cor_mv_base,
	slideShowArray:img_resguardos_duche,
	desc:'test'
}
var resguardos_banheira =
{
	title:'Resguardos Banheira',
	have_img: checkEmpty(img_resguardos_banheira),
	cor: cor_mv_media,
	slideShowArray:img_resguardos_banheira,
	desc:'test'
}
var acrilico = 
{
	title:'Acrílico',
	have_img: checkEmpty(img_acrilico),
	cor: cor_mv_leve,
	slideShowArray:img_acrilico,
	desc:'test'
}
var claraboias = 
{
	title:'Claraboias',
	have_img: checkEmpty(img_claraboias),
	cor: cor_mv_base,
	slideShowArray:img_claraboias,
	desc:'test'
}
var cortinas = 
{
	title:'Cortinas',
	have_img: checkEmpty(img_cortinas),
	cor: cor_mv_media,
	slideShowArray:img_cortinas,
	desc:'test'
}
var envidracados = 
{
	title:'Envidraçados',
	have_img: checkEmpty(img_envidracados),
	cor: cor_mv_leve,
	slideShowArray:img_envidracados,
	desc:'test'
}
var espelhos = 
{
	title:'Espelhos',
	have_img: checkEmpty(img_espelhos),
	cor: cor_mv_base,
	slideShowArray:img_espelhos,
	desc:'test'
}
var ferragens = 
{
	title:'Ferragens',
	have_img: checkEmpty(img_ferragens),
	cor: cor_mv_media,
	slideShowArray:img_ferragens,
	desc:'test'
}
var portas = 
{
	title:'Portas',
	have_img: checkEmpty(img_portas),
	cor: cor_mv_leve,
	slideShowArray:img_portas,
	desc:'test'
}
var revestimentos = 
{
	title:'Revestimentos',
	have_img: checkEmpty(img_revestimentos),
	cor: cor_mv_base,
	slideShowArray:img_revestimentos,
	desc:'test'
}
var vidro = 
{
	title:'Vidro',
	have_img: checkEmpty(img_vidro),
	cor: cor_mv_media,
	slideShowArray:img_vidro,
	desc:'test'
}
var guarda_corpos = 
{
	title:'Guarda-Corpos',
	have_img: checkEmpty(img_guarda_corpos),
	cor: cor_mv_leve,
	slideShowArray:img_guarda_corpos,
	desc:'test'
}
var palas = 
{
	title:'Palas',
	have_img: checkEmpty(img_palas),
	cor: cor_mv_base,
	slideShowArray:img_palas,
	desc:'test'
}
var outros = 
{
	title:'Outros',
	have_img: checkEmpty(img_outros),
	cor: cor_mv_media,
	slideShowArray:img_outros,
	desc:'test'
}




var products =
[
	resguardos_duche,
	resguardos_banheira,
	acrilico,
	claraboias,
	cortinas,
	envidracados,
	espelhos,
	ferragens,
	portas,
	revestimentos,
	vidro,
	guarda_corpos,
	palas,
	outros

]

// Cores estáticas _________________________________________________________________________________________________

// Variáveis locais ________________________________________________________________________________________________
const buttonStyle = 
{
    background: 'none',
    border: '0px',
};
const productsSplashProperties = 
{
	//apagar algumas destas cenas quando se adicionar mais elementos
    duration: 1000,
    pauseOnHover: true,
    canSwipe: true,
    easing: 'ease',
    infinite:true,
    prevArrow:<div className="productsSplashProperties">
	    					<button style={{ ...buttonStyle }}>
	    							<img src={icon_leftArrow}></img>
								</button>
						 </div>,

    nextArrow: <div className="productsSplashProperties">
		    					<button style={{ ...buttonStyle }}>
		    							<img src={icon_rightArrow}></img>
									</button>
							 </div>
    //indicators: true,
    //indicators: i => (<div className="indicator">___</div>)
};

function NewlineText(props) {
  const text = props.text;
  const newText = text.split('\n').map(str => <h3 className="products_single_text_title">{str}</h3>);
  return newText;
}


class ProductPopup extends React.Component{
	render(){
		return(
		  	<Popup 
		  		trigger=
		  		{
		  			<div>
		  				<div className="products_single_overlaycover" style={{backgroundColor:this.props.b.a.cor}}>
							
						</div>
						<div className="products_single_text">
								<h3 className="products_single_text_title">{this.props.b.a.title}</h3> 	
						</div>
					</div>
		  		}
		  		modal
		  		>
		  	  	{close => (
      				<div className="modal" >
	      				<div className="popupOverlay" onClick={close}></div>
	      				<div className="products_popUp_container">
	      					<Slide {...productsSplashProperties}>
										{
										this.props.b.a.slideShowArray.map((each, index) => 
											(
											<div className="products_popUp_container_PopSlideProducts" key={index}>
												<img src={each.img} key={index}/>
											</div>
											))
										}
									</Slide>
								</div>
								
    				  	<button className="close" onClick={close}>
    				  	  &times;
    				  	</button>
      				</div>
    			)}
					
					
		  	</Popup>
		);	
	}
}

class ProductItem extends React.Component{
	render(){
		const haveImg = this.props.a.have_img;
		let productBlock;
		if (haveImg) {
			productBlock = <div className="products_single">
								<img className="products_single_image" src={splash_sample1}></img>
								<ProductPopup b={this.props}/>
							</div>	
							
		} else {
			productBlock =  <div className="products_single">
								<div className="products_single_overlaycover_withoutImg" style={{backgroundColor:this.props.a.cor}}>
								<div className="products_single_text">
									<h3 className="products_single_text_title">{this.props.a.title}</h3> 	
								</div>
								</div>
							</div>
		}
		return(
			<div>
				{productBlock}
			</div>
		);
	}
}

export class Products extends React.Component{
	render(){
		return(
			<div>
				<div className = "products_heading">
					Produtos
				</div>
				<div className = "products_container">
		    		{products.map((each, index) =>
   		        		<ProductItem key={index} a={each}/>
   		        	)}
	      		</div>
      		</div>
		);
	}
}