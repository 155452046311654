
import React from 'react';
import { Slide } from 'react-slideshow-image';
import { Link } from 'react-scroll';
import { slide as Menu } from 'react-burger-menu'

import 'react-slideshow-image/dist/styles.css'
import './css/main.css'
import './css/navbar.css'
import './css/page_landing.css'
import './css/page_about.css'
import './css/page_reviews.css'
import './css/page_products.css'
import './css/page_contacts.css'
import './css/page_legal.css'

import Main                    from './Main.js'
import logoTopnav              from './assets/logo_long_dark.png'
import logoBurger              from './assets/logo_small_dark.png'

const convertVhToPx = (vh=13) => {
    const oneVhInPx = window.innerHeight / 100;
    return oneVhInPx * vh;
};



class HamburgerMenu extends React.Component {
  constructor (props) {
    super(props)
    var isitopen = props.isitopen;
    this.state = {
      menuOpen: false
    }

  }
  handleStateChange (state) {
    this.setState({menuOpen:this.props.isitopen})
  }
  closeMenu () {
    this.setState({menuOpen: false})
  }
  closeMenu () {
    this.setState({menuOpen: false})
  }
  showSettings (event) {
    event.preventDefault();
  }
  render () {
    // VER ESTE LINK: https://github.com/negomi/react-burger-menu/wiki/FAQ#i-want-to-control-the-open-state-programmatically-but-i-dont-understand-how-to-use-the-isopen-prop
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling

    return (
      <Menu isOpen={this.state.menuOpen}
            onStateChange={(state) => this.handleStateChange(state)}
            right burgerButtonClassName={ "burgerbutton" }
            width={"100vw"}>

         <Link to="BeginPage-ID" spy={true} smooth={true} offset={-convertVhToPx()} onClick={() => this.closeMenu()}>
                <img src={logoBurger} className="APP_NC_L_L_logoimgBurger" ></img>
            </Link> 

        <div className="APP_BC_menuItem">
          <Link onClick={() => this.closeMenu()} to="AboutPage-ID" spy={true} isDynamic={true} smooth={true} offset={-convertVhToPx()}>Empresa</Link>
        </div>

        <div className="APP_BC_menuItem">
            <Link onClick={() => this.closeMenu()} to="ProductsPage-ID" spy={true} isDynamic={true} smooth={true} offset={-convertVhToPx()}>Produtos</Link>
        </div>

        <div className="APP_BC_menuItem">
            <Link onClick={() => this.closeMenu()} to="Footer-ID" spy={true} isDynamic={true} smooth={true} offset={-convertVhToPx()}>Contactos</Link>
        </div>
      </Menu>
    );
  }
}

class APP_NavigationComponent extends React.Component{
  constructor(props){

    super(props);
    this.state =
    {
      areMenusOpen:false
    }
  }



  render()
  {

    const closeAllmenus = (e) => {
      e=e||window.event;
      console.log("Trying to close")
      this.setState({areMenusOpen:false});
    };
    return(
    <div className="topnav">
        <div className="APP_NavigationFiller">
            <div className="APP_BC_LEFT">
              <div className="APP_NC_L_logobox">
                  
              </div>
            </div>
        </div>

        <div className="APP_NavigationComponent">
            <Link to="BeginPage-ID" spy={true} smooth={true} offset={-convertVhToPx()} onClick={event => closeAllmenus(event)}>
                <img src={logoTopnav} className="APP_NC_L_L_logoimg" alt="Logótipo da Mondegovidro"></img>
            </Link>

            <div className="APP_NC_RIGHT">
              <div className="APP_NC_R_menuItem">
                  <Link to="AboutPage-ID" spy={true} isDynamic={true} smooth={true} offset={-convertVhToPx()}>Empresa</Link>
              </div>
              <div className="APP_NC_R_menuItem">
                  <Link to="ProductsPage-ID" spy={true} isDynamic={true} smooth={true} offset={-convertVhToPx()}>Produtos</Link>
              </div>
              <div className="APP_NC_R_menuItem">
                  <Link to="Footer-ID" spy={true} isDynamic={true} smooth={true} offset={-convertVhToPx()}>Contactos</Link>
              </div>
            </div>
        </div>

        <div className="APP_NavigationComponent_Burger">
          <div className="APP_NC_LEFT">
            <div className="APP_NC_L_logobox">
              <Link to="BeginPage-ID" spy={true} smooth={true} offset={-convertVhToPx()}>
                  <img src={logoBurger} className="APP_NC_L_L_logoimg" alt="logo Mondegovidro"></img>
                </Link>
            </div>
          </div>
        </div>

          <div className="burgerHider">
          <HamburgerMenu/>
          </div>
      </div>
      );
  }
}


const APP_Main = () => (
  <Main/>
);

function App() {
  return (
    <div id="app">
      <APP_NavigationComponent/>
      <div className="site">
        <APP_Main/>
      </div>
    </div>
  );
}

export default App;
