import React, {Component}        from 'react';
import Popup                     from 'reactjs-popup';
import {Slide}                from 'react-slideshow-image';

import splash_sample1      from './assets/splash/splash_sample1.jpg'
const splashProperties =
{
   //apagar algumas destas cenas quando se adicionar mais elementos
    duration: 700000000,
    pauseOnHover: true,
    arrows: false,
    canSwipe: false,
    easing: 'ease',
    centerMode: true
};
const slideShowArray =
[
   {
      image:splash_sample1,
      //text: 'Empresa líder na produção e instalação de vidros para aplicações domésticas ou empresariais.',
      //header: 'Bem vindo à MondegoVidro.',
      header: 'Empresa líder na produção e instalação de vidros para aplicações domésticas ou empresariais.',
      buttonText: '+'
   }//,
   //{
   // image:splash_sample3,
   // header: 'Resguardos de Duche.',
   // text: 'Linhas modernas, simples e elegantes para o seu lar',
   // buttonText: 'Explore os nossos produtos'
   //},
   //{
   // image:splash_sample2,
   // header: 'Instalações Personalizadas.',
   // text: 'Planos personalizados, elementos escolhidos manualmente e altamente configuráveis com a ajuda dos nossos especialistas.',
   // buttonText: 'Explore os nossos produtos'
   //},
   //{
   // image:splash_clients,
   // header: 'Nós valorizamos o seu interesse.',
   // text: 'Contacte a nossa equipa agora para obter o seu orçamento personalizado.',
   // buttonText: 'Entre em contacto'
   //}
];
// Landing Component (Slideshow)___________________________
export class Landing extends React.Component{
	render(){
		return (
	   		<div className="slide-container landing_content">
   		   	 	{/* This for some reason isn't working... Sad. Slideshow would make this better.*/}

   		   	 	<Slide {...splashProperties}>
   		      	{
   		        	slideShowArray.map((each, index) =>
   		        		<LandingSlide key={index} slide={each}/>
   		        	)
   		      	}
   		    	</Slide>
   		    	{/*<LandingSlide slide={slideShowArray[0]}/>*/}
   		  </div>
   		);
	}
}
class LandingSlide extends React.Component{
	render(){
		return(
		<div className="landing_slide">
	   		  	<div className="landing_slide_single">
   		  			<p className="landing_slide_single_text">{this.props.slide.header}</p>
   		  			{/*<p className="landing_slide_single_text">{this.props.slide.text}</p>*/}
   		  		{/*
   		  			<Link to="ProductsPage-ID" spy={true} smooth={true} offset={-convertVhToPx()}>
   		  				<img className="landing_slide_single_imgButton" src={icon_new_plus}/>
   		  			</Link>
   		  		*/}
   		  		</div>
   		      <img src={this.props.slide.image} style={{width:"100%"}}/>
   		</div>
   		);
	}
}