

let a = new Date()
let ano = a.getFullYear()


export const LegalBanner = () =>{
	
  const ComplainsBookComponent = () =>{
    return(
      <a href="https://www.livroreclamacoes.pt/inicio/">
        Livro de Reclamações
      </a>
    )
  }

  const RalEntityComponent = () =>{
    return(
      <a href="https://cacrc.pt/">
        Canal de Denúncias
      </a>
    )
  }

  const CompanyYearBannerComponent = () =>{
    return(
      <>
        ©MONDEGOVIDRO {ano}, all rights reserved
      </>
    )
  }
  
  const CookiesDisclaimerComponent = () =>{
    return(
      <div className="legal-disclaimer">
        <div className="legal-disclaimer-inner"> 
          Este sítio Web não utiliza qualquer tipo de cookies nem se responsabiliza pela navegação a ligações externas.
        </div>
      
      </div>
    )
  }

  return(
    <>
      <div className="legal-banner">
          <div className="">
          <ComplainsBookComponent/>
          </div>
          <div className="contacts_footer">
          <CompanyYearBannerComponent/>
          </div>
          <div className="">
          <RalEntityComponent/>
          </div>
      </div>
      <div className="legal-banner-mobile">
        <div className="">
          <ComplainsBookComponent />
        </div>
        <div className="">
          <RalEntityComponent />
        </div>
        <div className="contacts_footer">
          <CompanyYearBannerComponent />
        </div>
    </div >
      <CookiesDisclaimerComponent/>
    </>
  )
}