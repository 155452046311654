import React, {Component} 			from 'react';
import Popup            			from 'reactjs-popup';
import  {Slide}  					from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import {reviewsArray} 				from './imglib/criticas_utilizadores/reviews.js'

const reviewProperties =
{
	arrows:false,
	easing: 'ease',
	transitionDuration: 300,
    indicators: i => (<div className="indicator">•</div>)
};

class ReviewItem extends React.Component{
	render(){
		return(
			<div className="review_item_single">
				<div className="review_item_single_starContainer">
					{[...Array(this.props.data.stars)].map((e, i) => <span key={i} className="reviewStar">★</span>)}
				</div>
				<p className="review_item_single_comment">"{this.props.data.comment}"</p>
				<span className="review_item_single_author">{this.props.data.author}</span>
			</div>
		);
	}
}
export class Reviews extends React.Component{
	render(){

		var reviewsArrayCleaned = reviewsArray.filter(x => x.stars != 0);

		return(
			<div className="reviews_block">
        		<div className="reviews_block_content">
					<h1 className="reviews_heading">Os nossos clientes falam por nós.</h1>
					<div className="reviews_slideshow">

						<Slide {...reviewProperties}>
   		      			{
   		        			reviewsArrayCleaned.map(
   		        				(each, index) => <ReviewItem key={index} data={each}/>
   		        				
   		        			)
   		      			}
   		    			</Slide>
					</div>
				</div>
    		</div>
    	);
	}
}