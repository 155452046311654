import React, {Component} 			from 'react';
import icon_installation 	from './assets/icons/icon_installation.png'
import icon_sketch 			from './assets/icons/icon_sketch.png'
import icon_solutions 		from './assets/icons/icon_solutions.png'
import icon_shower 			from './assets/icons/icon_shower.png'
const companySummaryArray =
[
	{
		icon:icon_shower,
		title:'Qualidade & Excelência',
		text:'Instalações altamente profissionais com acabamento de qualidade.'
	},
	{
		icon:icon_sketch,
		title:'Cumprimento',
		text:'Planificação transparente e clara, comunicação aberta e honesta.'
	},
	{
		icon:icon_installation,
		title:'Experiência',
		text:'Equipa altamente qualificada e experiente, com uma larga gama de produtos.'
	},
	{
		icon:icon_solutions,
		title:'Seriedade & Confiança',
		text:'Décadas de experiência na instalação de vidros.'
	}
];

// About + PS section______________________________________
export class About extends React.Component{
    render(){
		return(
			<div>
				{/* História */}
				<div className="about_textblock">
				<h1>
					História
				</h1>

				<span>
					Assente numa base de experiência e presença no mercado com quase 50 anos, a Mondegovidro, representa a passagem de testemunho para a segunda geração de uma equipa dedicada à execução de projetos combinados com vidro.<br></br>
Fundada em 2016, a Mondegovidro dedica-se ao fornecimento de soluções em vidro de alta qualidade para os mais diversos tipos de construções. Desde a sua fundação que a Mondegovidro tem vindo a crescer e expandir a sua gama de produtos e serviços para atender às necessidades crescentes dos seus clientes, através de uma equipa multidisciplinar e tecnologias avançadas que permitem uma produção precisa e eficiente.

				</span>
				
				</div>
				{/* Missão */}
				<div className="about_textblock">
					<h1>
						A Missão
					</h1>
					
					<span>
					Fornecer soluções em vidro adaptadas às necessidades dos nossos clientes, concebidas com materiais de excelente qualidade, elevado nível de serviço e atenção aos factores ecológicos, obtendo uma duração superior aos prazos da garantia por lei e diminuindo ao máximo a pegada ecológica e sendo socialmente responsável.
					</span>
					
				</div>
				{/* Valores */}
				<div className="about_textblock">
					<h1>
						Os Nossos Valores
					</h1>
					
					<span>
						Princípios básicos que definem da nossa empresa, ou seja, quem somos e nossa atitude perante a execução.
						<p/><strong>Comunicação</strong> é a palavra-chave para os bons resultados e por isso um dos nossos objectivos diários é comunicar mais e melhor. Todos podem colocar questões, dar opiniões, debater ideias e antecipar problemas. Consideramos importantes os nossos procedimentos, a organização e sobretudo a excelente comunicação como os nossos colegas, fornecedores e clientes.
						<p/><strong>Brio</strong> está intrínseco no nosso dia-a-dia - não dispensamos o cuidado com a mercadoria, as ferramentas e materiais adequados para a execução da sua tarefa, reduzir custos, eficiência e total transparência com os os nossos clientes.
						<p/><strong>Rigor</strong> é fundamental quando trabalhamos o vidro. Termos noção de que não há ajustes e tudo é feito ao detalhe - desde o levantamento de medidas, à execução e acabamento, à gestão de expectativas dos clientes.
					</span>
					
				</div>
				{/* Icones */}
				<div className="about_infoblock full-width">
					{companySummaryArray.map((summary, i) =>
						<AboutItemIconic prodSummary={summary} key={i}/>)
					}
    			</div>
    		</div>
    	);
	}
}
class AboutItemIconic extends React.Component{
	render(){
		return(
			<div className="about_infoblock_single">
				<img className="about_infoblock_single_icon" src={this.props.prodSummary.icon}/>
				<h4 className="about_infoblock_single_title">{this.props.prodSummary.title}</h4>
				<p className="about_infoblock_single_text">{this.props.prodSummary.text} 	</p>
			</div>
		);
	}
}
