import React, {Component}       from 'react';
import icon_new_phone     from './assets/icons/new_phone.svg'
import icon_new_email     from './assets/icons/new_mail.svg'
import icon_new_location  from './assets/icons/new_map.svg'
// Contacts________________________________________________
export class Contacts extends React.Component{
	render(){
		return(
			<div className = "contacts_section">
				<div className = "contacts_wrapper">
		 			<div className = "contacts_container">
           				<div className ="contacts_block">
	            			<div className = "contacts_single_frame" >
	            				<a href='https://www.google.pt/maps/place/Mondegovidro+-+Solu%C3%A7%C3%B5es+em+vidro,+lda./@40.1615101,-8.4470453,19.5z/data=!4m8!1m2!2m1!1sRua+do+Outeiro+Negro+Armaz%C3%A9m+2+Palheira+3040-692+Assafarge!3m4!1s0xd22f829ce887943:0x82bf36f54dbc37ba!8m2!3d40.1612177!4d-8.4469045?hl=pt-PT' target="_blank">
	            					<img src = {icon_new_location} alt="morada Mondegovidro"/>
	            				</a>
	            				<h4>Visite</h4>
	            				<a href='https://www.google.pt/maps/place/Mondegovidro+-+Solu%C3%A7%C3%B5es+em+vidro,+lda./@40.1615101,-8.4470453,19.5z/data=!4m8!1m2!2m1!1sRua+do+Outeiro+Negro+Armaz%C3%A9m+2+Palheira+3040-692+Assafarge!3m4!1s0xd22f829ce887943:0x82bf36f54dbc37ba!8m2!3d40.1612177!4d-8.4469045?hl=pt-PT' target="_blank">
	            					<h3>Rua do Outeiro Negro</h3>
	            					<h3>Armazém 2, Palheira</h3>
	            					<h3>3040-692 Assafarge</h3>
	            					
	            				</a>
	            			</div>
           				</div>

           				<div className = "contacts_block">
            				<div className = "contacts_single_frame">
            					<a href="tel:+351239438095">
            						<img src = {icon_new_phone} alt="Mondegovidro contacto"/>
            					</a>
            					<h4>Ligue</h4>
            					<a href="tel:+351239438095">
            						<h3>(+351) 239 438 095</h3>
            					</a>
            					<div class="contacts_single_frame_nota">
            						<p>Custo de uma chamada para a rede fixa de acordo com o seu tarifário.</p>
            					</div>
            					<br/>
            				</div>
           				</div>

           				<div className = "contacts_block">
            				<div className = "contacts_single_frame">
            					<a href='mailto:geral@mondegovidro.com'>
            						<img src = {icon_new_email} alt="enviar e-mail Mondegovidro"/>
            					</a>
								<h4>Contacte</h4>
            					<a href='mailto:geral@mondegovidro.com'>
            						<h3>geral@mondegovidro.com</h3>
            					</a>
            				</div>
           				</div>
         			</div>
    			</div>
    			
			</div>

		);
	}
}
