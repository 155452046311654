
// Imports _______________________________________________________________________________________________
import React 						from 'react';
import {Products} 					from './ProductsComponent.js'
import {Reviews} 					from './ReviewsComponent.js'
import {Landing}					from './LandingComponent.js'
import {Contacts}					from './ContactsComponent.js'
import {About}						from './AboutComponent.js'
import {LegalBanner}				from './LegalBannerComponent.js'

// Default Class ________________________________________________________________________________________
export default class Page extends React.Component{
	render(){
		return (
      		<div className="main-content">
				<div className="section" id = 'BeginPage-ID'>
					<Landing/>
				</div>
				<div className="section padded_top padded_bottom" id = 'AboutPage-ID'>
					<About/>
				</div>
				<div className="section blue_bg">
					<Reviews/>
				</div>
				<div className="section padded_top padded_bottom" id = 'ProductsPage-ID'>
					<Products/>
				</div>
				<div className="section blue_bg" id = 'Footer-ID'>
					<Contacts/>
				</div>
        
				<div className="" id="">
					<LegalBanner />
				</div>
				
      		</div>
    	);
	}
}
