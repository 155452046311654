export const reviewsArray =
[
	{////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		stars:4,
		author:'Pedro Lucas',
		comment:'Excelente atendimento, com os colaboradores disponíveis para qualquer esclarecimento.'
		
	},
	{////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		stars:5,
		author:'Telmo Silva',
		comment:'Simpatia e excelente serviço!. Gostei sobretudo do conhecimento técnico.'
	},
	{////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		stars:5,
		author:'Nuno Bras',
		comment:'Excelentes profissionais.'
		
	},
	{////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		stars:0,
		author:'AUTOR',
		comment:'COMENTARIO.'
		
	},
	{////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		stars:0,
		author:'AUTOR',
		comment:'COMENTARIO.'
		
	},
	{////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		stars:0,
		author:'AUTOR',
		comment:'COMENTARIO.'
		
	},
	{////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		stars:0,
		author:'AUTOR',
		comment:'COMENTARIO.'
		
	},
	{////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		stars:0,
		author:'AUTOR',
		comment:'COMENTARIO.'
		
	},
	{////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		stars:0,
		author:'AUTOR',
		comment:'COMENTARIO.'
		
	},
	{////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		stars:0,
		author:'AUTOR',
		comment:'COMENTARIO.'
		
	},
	{////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		stars:0,
		author:'AUTOR',
		comment:'COMENTARIO.'
		
	},
	{////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		stars:0,
		author:'AUTOR',
		comment:'COMENTARIO.'
		
	},
	{////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		stars:0,
		author:'AUTOR',
		comment:'COMENTARIO.'
		
	},
	{////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		stars:0,
		author:'AUTOR',
		comment:'COMENTARIO.'
		
	},
	{////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		stars:0,
		author:'AUTOR',
		comment:'COMENTARIO.'
		
	},
	{////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		stars:0,
		author:'AUTOR',
		comment:'COMENTARIO.'
		
	},
	{////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		stars:0,
		author:'AUTOR',
		comment:'COMENTARIO.'
		
	}
];